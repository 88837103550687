import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';

import eventsBg from './images/events-bg.png';

import event1 from './images/Home_Feature_1.jpg';
import event2 from './images/Home_Feature_2.jpg';
import event3 from './images/Home_Feature_3.jpg';

const StyledSection = styled.section`
	background: url(${eventsBg});
	background-size: 100%;
	min-height: 500px;
	position: relative;
	padding: 3rem 0;

	.constrain {
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		flex-direction: row;

		@media (max-width: 999px) {
			flex-direction: column;
		}
	}
	.event-tile {
		padding: 1rem;
		text-align: center;
		max-width: 33%;

		cursor: pointer;
		transition: transform ease 300ms;
		&:hover {
			transform: scale(1.05);
		}

		@media (max-width: 999px) {
			max-width: 400px;
		}

		.topper {
			background-color: ${(p) => p.theme.color.yellow};

			padding-bottom: 75%;
			background-size: cover;
			background-position: center center;
		}
		.inner {
			background: black;
			color: white;
			padding: 2rem;
			min-height: 220px;
			position: relative;
			h3 {
				font-size: 1.5rem;
				margin-bottom: 1rem;
			}
			p {
				margin-bottom: 1rem;
			}
			a {
				color: ${(p) => p.theme.color.yellow};
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.soon {
			font-style: italic;
			color: ${(p) => p.theme.color.yellow};
			position: absolute;
			bottom: 2rem;
			width: calc(100% - 4rem);
			text-align: center;
		}
	}
`;

export default ({ tiles }) => (
	<StyledSection>
		<div className="constrain">
			{tiles.map((tile) => (
				<div className="event-tile">
					<a href={tile.link ? tile.link : '#'}>
						<div
							className="topper"
							style={{ backgroundImage: `url('${tile.image}')` }}
						></div>
						<div className="inner">
							<h3>{tile.title}</h3>
							<div dangerouslySetInnerHTML={{ __html: tile.details }} />
						</div>
					</a>
				</div>
			))}
		</div>
	</StyledSection>
);
