import React from 'react';
import styled from 'styled-components';

//import bar from './images/bar.png';
import bar from '../../../images/border-strip.svg';

const StyledDivider = styled.div`
	background-size: cover;
	background-repeat: repeat-x;
	background-position: center;
	height: 32px;
	width: 100%;
	position: relative;
	margin-bottom: -1rem;
	z-index: 5;
	background-size: auto 24px;
	background-color: black;
`;

export default () => (
	<StyledDivider style={{ backgroundImage: `url(${bar})` }} />
);
