import React from 'react';
import styled from 'styled-components';

import Button from '../../shared/button';

import tanksBg from './images/home-tanks.png';
//import cans from './images/conveyor-cans.png';
import cans from './images/ConveyorBeers2.png';
import conveyor from './images/conveyor.png';

import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from '../hero/parallaxImage';

const StyledSection = styled.section`
	background: url(${tanksBg});
	background-size: cover;
	background-position: center right;
	min-height: 800px;
	position: relative;

	@media (max-width: 1300px) {
		min-height: 700px;
	}

	@media (max-width: 999px) {
		min-height: 600px;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #ffffff8f;
		}
	}

	@media (max-width: 769px) {
		min-height: 500px;
	}

	@media (min-width: 769px) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.container {
		height: 100%;
	}

	.inner-content {
		max-width: 450px;

		h2 {
			font-size: 4rem;
			margin-bottom: 1rem;
		}
		p {
			font-size: 1.25rem;
		}
		@media (max-width: 1300px) {
			margin-left: -4rem;
		}
		@media (max-width: 999px) {
			margin-left: 0;
			h2 {
				font-size: 2.5rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
	.cans {
		position: absolute;
		right: 0;
		/*bottom: 44px;*/
		bottom: 3vw;
		left: 0;
		width: 100%;
	}
	.conveyor {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.buttons-row {
		margin-top: 1rem;

		.column {
			padding: 0;
		}

		.column:nth-child(2) {
			a {
				width: 11rem;
			}
		}
	}
`;

export default ({ conveyorCans, title, blurb }) => (
	<StyledSection className="section">
		<div className="container">
			<div className="columns is-vcentered">
				<div className="column"></div>
				<div className="column">
					<div className="inner-content">
						<h2>{title}</h2>
						<div dangerouslySetInnerHTML={{ __html: blurb }} />
						<div className="columns buttons-row is-mobile">
							<div className="column is-narrow">
								<Button element={'a'} link={'/brewery'} background={'two'}>
									The Brewery
								</Button>
							</div>
							<div className="column is-narrow">
								<Button element={'a'} link={'/tours'} background={'one'}>
									Tours
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<img src={conveyor} alt="conveyor" className="conveyor" />
		<Parallax x={[-5, 5]} className="cans easing">
			<ParallaxImage src={conveyorCans} alt="cans" />
		</Parallax>
	</StyledSection>
);
