import React from 'react';
import styled from 'styled-components';

const StyledOverlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	transition: opacity 300ms ease, z-index 1ms ease 400ms;
	opacity: ${(p) => (p.isShown ? '1.0' : '0')};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: black;
	z-index: ${(p) => (p.isShown ? '100' : '-100')};
	background-image: url('/img/FM_Load.gif');
	background-size: 200px 200px;
	background-position: center center;
	background-repeat: no-repeat;
`;

const Overlay = ({ isShown, children }) => {
	return <StyledOverlay isShown={isShown}>{children}</StyledOverlay>;
};

export default Overlay;
