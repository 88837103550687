import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import buttonOneBg from './images/button-one-bg.svg';
import buttonTwoBg from './images/button-two-bg.svg';

const StyledButton = styled.button`
	display: inline-block;
	cursor: pointer;
	font-family: ${(p) => p.theme.font.display};
	font-size: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.1em;
	text-align: center;
	color: ${(p) => p.theme.color.light};
	text-transform: uppercase;
	background: none;
	background-image: url(${(p) => (p.bg === 'one' ? buttonOneBg : buttonTwoBg)});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border: none;
	padding: 0.5em 1.5em;
	transition: all ease 300ms;
	&:hover {
		color: white !important;
		transform: scale(1.05);
	}
`;

const StyledLink = styled.a`
	display: inline-block;
	font-family: ${(p) => p.theme.font.display};
	font-size: 1.5rem;
	font-weight: 400;
	letter-spacing: 0.1em;
	text-align: center;
	color: ${(p) => p.theme.color.light};
	text-transform: uppercase;
	background: none;
	background-image: url(${(p) => (p.bg === 'one' ? buttonOneBg : buttonTwoBg)});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border: none;
	padding: 0.5em 1.5em;
	transition: all ease 300;
	transition: all ease 300ms;
	&:hover {
		color: white !important;
		transform: scale(1.05);
	}
`;

const Button = ({
	element,
	background,
	link,
	children,
	external,
	...props
}) => {
	if (element === 'button') {
		return (
			<StyledButton bg={background} {...props}>
				{children}
			</StyledButton>
		);
	} else {
		if (external) {
			return (
				<StyledLink
					href={link}
					bg={background}
					target="_blank"
					rel="noopener noreferrer"
					{...props}
				>
					{children}
				</StyledLink>
			);
		}
		return (
			<StyledLink href={link} bg={background} {...props}>
				{children}
			</StyledLink>
		);
	}
};

Button.propTypes = {
	element: PropTypes.oneOf(['button', 'a']),
	background: PropTypes.oneOf(['one', 'two']),
	link: PropTypes.string,
};

export default Button;
