import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import OnImagesLoaded from 'react-on-images-loaded';
import Overlay from '../components/shared/overlay';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import Divide from '../components/shared/divide';

import Hero from '../components/home/hero';
import Tanks from '../components/home/tanks';
import Taps from '../components/home/taps';
import Events from '../components/home/events';

export const query = graphql`
	query {
		prismicHomepage {
			prismicId
			data {
				beer_section_title {
					text
				}
				beer_section_blurb {
					html
				}
				conveyor_belt_section_title {
					text
				}
				conveyor_belt_blurb {
					html
				}
				grass_cans {
					url
				}
				conveyor_belt_beers {
					url
				}
				tile_1 {
					document {
						__typename
						... on PrismicHomepageTile {
							data {
								tile_title {
									text
								}
								tile_image {
									url
									localFile {
										publicURL
									}
								}
								tile_details {
									html
								}
								tile_link {
									url
								}
							}
						}
					}
				}
				tile_2 {
					document {
						__typename
						... on PrismicHomepageTile {
							data {
								tile_title {
									text
								}
								tile_image {
									url
									localFile {
										publicURL
									}
								}
								tile_details {
									html
								}
								tile_link {
									url
								}
							}
						}
					}
				}
				tile_3 {
					document {
						__typename
						... on PrismicHomepageTile {
							data {
								tile_title {
									text
								}
								tile_image {
									url
									localFile {
										publicURL
									}
								}
								tile_details {
									html
								}
								tile_link {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;

const PageTemplate = ({ data }) => {
	const [isLoaded, setIsLoaded] = useState(true);

	const tiles = [
		{
			title: data.prismicHomepage.data.tile_1.document.data.tile_title.text,
			image: data.prismicHomepage.data.tile_1.document.data.tile_image.url,
			details: data.prismicHomepage.data.tile_1.document.data.tile_details.html,
			link: data.prismicHomepage.data.tile_1.document.data.tile_link.url,
		},
		{
			title: data.prismicHomepage.data.tile_2.document.data.tile_title.text,
			image: data.prismicHomepage.data.tile_2.document.data.tile_image.url,
			details: data.prismicHomepage.data.tile_2.document.data.tile_details.html,
			link: data.prismicHomepage.data.tile_2.document.data.tile_link.url,
		},
		{
			title: data.prismicHomepage.data.tile_3.document.data.tile_title.text,
			image: data.prismicHomepage.data.tile_3.document.data.tile_image.url,
			details: data.prismicHomepage.data.tile_3.document.data.tile_details.html,
			link: data.prismicHomepage.data.tile_3.document.data.tile_link.url,
		},
	];

	return (
		<Layout home={true}>
			<SEO title="Home" />
			<Overlay isShown={isLoaded} />
			<OnImagesLoaded
				onLoaded={() => setIsLoaded(false)}
				onTimeout={() => setIsLoaded(false)}
				timeout={5000}
			>
				<Hero
					grassCans={data.prismicHomepage.data.grass_cans.url}
					title={data.prismicHomepage.data.beer_section_title.text}
					blurb={data.prismicHomepage.data.beer_section_blurb.html}
				/>
			</OnImagesLoaded>
			<Divide />
			<Tanks
				title={data.prismicHomepage.data.conveyor_belt_section_title.text}
				blurb={data.prismicHomepage.data.conveyor_belt_blurb.html}
				conveyorCans={data.prismicHomepage.data.conveyor_belt_beers.url}
			/>
			<Taps />
			<Divide />
			<Events tiles={tiles} />
		</Layout>
	);
};

export default withPreview(PageTemplate);
