import React from 'react';
import styled from 'styled-components';

import Button from '../../shared/button';

import tapsBg from './images/taps-bg.png';
import taps from './images/taps2.png';
import board from './images/board.png';

const StyledSection = styled.section`
	background: url(${tapsBg});
	background-size: cover;
	background-position: center;
	position: relative;
	margin-bottom: -1rem;
	padding: 0 1.5rem;

	@media (max-width: 999px) {
		min-height: 150vw;
	}

	@media (min-width: 999px) {
		min-height: 1200px;
		height: 80vw;
	}

	.board-outer {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 600px;

		.board {
			background-image: url(${board});
			background-size: contain;
			background-position: center top;
			background-repeat: no-repeat;
			text-align: center;
			padding-top: 89%;

			.board-content {
				position: absolute;
				top: 45%;
				width: 100%;

				h2 {
					font-size: 2.5rem;
					color: white;
					text-align: center;

					@media (max-width: 999px) {
						font-size: calc(4.5vw + 10px);
					}
				}

				.buttons-row {
					margin-top: 1rem;

					@media (max-width: 499px) {
						margin-top: 30vw;
					}

					.column {
						padding: 0;

						&:nth-child(1) {
							a {
								width: 10rem;
							}
						}
						&:nth-child(2) {
							a {
								width: 140px;
							}
						}
					}
				}
			}
		}
	}

	.taps-outer {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-top: 41%;

		.taps-bg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: url(${taps});
			background-size: contain;
			background-position: center bottom;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
		}
	}
`;

export default () => (
	<StyledSection>
		<div className="container">
			<div className="board-outer">
				<div className="board">
					<div className="board-content">
						<h2>
							THE FUNKIEST TAP ROOM
							<br /> THIS SIDE OF KEMPENFELT BAY
						</h2>
						<div className="columns is-centered buttons-row is-mobile">
							<div className="column is-narrow">
								<Button element={'a'} background={'two'} link={'/taproom#taps'}>
									On Tap
								</Button>
							</div>
							<div className="column is-narrow">
								<Button element={'a'} background={'one'} link={'/taproom#food'}>
									Menu
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="taps-outer">
			<div className="taps-bg"></div>
		</div>
	</StyledSection>
);
