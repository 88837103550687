import React from 'react';
import { withController } from 'react-scroll-parallax';

/*
 * This component updates the parallax controller
 * each time an image loads, so that the parallax controller
 * can accommodate for each image's dimensions
 * so that the positioning of the images doesn't break.
 */
const ParallaxImage = ({ src, alt, parallaxController }) => {
	const handleLoad = () => {
		parallaxController.update();
	};
	// eslint-disable-next-line
	return <img src={src} alt={alt} onLoad={() => handleLoad()} />;
};

export default withController(ParallaxImage);
