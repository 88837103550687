import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Button from '../../shared/button';
import ParallaxImage from './parallaxImage';

import sky from './images/hero-sky.jpg';
import brewery from './images/hero-brewery2.png';
import cans from './images/hero-cans2.png';
import beer from '../../../images/beer-layer.png';
import truck from './images/truck2.png';

const StyledHero = styled.div`
	background-color: ${(p) => p.theme.color.yellow};
	margin-bottom: -1.5rem;
	overflow: hidden;
	@media (max-width: 999px) {
		padding-top: 1rem;
	}

	.parallax-outer {
		.parallax-inner {
			img {
				width: 100%;
			}
		}

		&.easing {
			.parallax-inner {
				transition: 700ms ease-out;
			}
		}
	}

	.hero-top {
		position: relative;
		padding-top: 73%;
		@media (max-width: 999px) {
			padding-top: 81%;
		}
		.parallax-outer {
			position: absolute;

			&.sky {
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			&.brewery {
				width: 100%;
				height: 100%;
				top: 0;
			}

			&.truck {
				top: 50vw;
				right: 0;
				bottom: 0;
				left: 0;

				.parallax-inner {
					text-align: center;

					img {
						width: 500px;
						max-width: 30%;
					}
				}
			}

			&.cans {
				width: 100%;
				bottom: -3rem;
			}
		}
	}

	.hero-bottom {
		position: relative;

		.beer-head {
			position: absolute;
			top: 0;
			width: 100%;

			@media (min-width: 1400px) {
				margin-top: -2vw;
			}

			@media (max-width: 999px) {
				margin-top: -2vw;
			}

			@media (min-width: 769px) and (max-width: 999px) {
				margin-top: 1vw;
			}
		}
		.beer {
			padding: 3rem 1rem 5rem;
			@media (max-width: 999px) {
				padding-top: 0;
			}
			.beer-content {
				text-align: center;
				max-width: 800px;
				margin: 0 auto;
				z-index: 4;
				h1 {
					font-size: 4rem;
					margin-bottom: 1rem;
					@media (max-width: 999px) {
						font-size: 2rem;
					}
				}
				p {
					font-size: 20px;
					line-height: 1.5rem;
					margin-bottom: 2rem;
					@media (max-width: 999px) {
						font-size: 18px;
					}
				}
			}
		}
	}
`;

export default ({ grassCans, blurb, title }) => (
	<StyledHero>
		<div className="hero-top">
			<Parallax
				y={[-60, 0]}
				styleOuter={{}}
				styleInner={{
					height: `100%`,
					width: `100%`,
					backgroundImage: `url(${sky})`,
				}}
				className="sky easing"
			></Parallax>
			<Parallax y={[6, -10]} className="brewery easing">
				<ParallaxImage src={brewery} alt="Brewery" />
			</Parallax>
			<Parallax y={[10, -50]} x={[-20, 10]} className="truck easing">
				<ParallaxImage src={truck} alt="Truck" />
			</Parallax>
			<Parallax y={[30, -50]} className="cans easing">
				<ParallaxImage src={grassCans} alt="Cans" />
			</Parallax>
		</div>
		<div className="hero-bottom">
			<Parallax y={[-10, -80]} className="beer-head easing">
				<ParallaxImage src={beer} alt="Beer Head" />
			</Parallax>
			<Parallax y={[10, -10]} className="beer easing">
				<div className="beer-content">
					<h1>{title}</h1>
					<div dangerouslySetInnerHTML={{ __html: blurb }} />
					<div className="has-text-centered">
						<Button element={'a'} background={'one'} link={'/beers'}>
							Our Beers
						</Button>
					</div>
				</div>
			</Parallax>
		</div>
	</StyledHero>
);
